import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, ROUTES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsEmptyString } from '@utils';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-breadcrumbs',
	LIST: 'ac-breadcrumbs__list',
	ITEM: 'ac-breadcrumbs__item',
	SEPARATOR: 'ac-breadcrumbs__item--separator',
	LINK: 'ac-breadcrumbs__link',
	ACTIVE: 'ac-breadcrumbs__link--active',
	EMPTY: 'ac-breadcrumbs__link--empty',
};

const AcBreadcrumbs = ({ store: { ui } }) => {
	const { current_breadcrumbs } = ui;

	const getActiveLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.LINK, _CLASSES.ACTIVE);
	}, []);

	const getEmptyLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.LINK, _CLASSES.EMPTY);
	}, []);

	const getLinkClassNames = useMemo(() => {
		return clsx(_CLASSES.LINK);
	}, []);

	const getSeparatorClassNames = useMemo(() => {
		return clsx(_CLASSES.ITEM, _CLASSES.SEPARATOR);
	}, []);

	const getItemClassNames = useMemo(() => {
		return clsx(_CLASSES.ITEM);
	}, []);

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderSeparator = (n) => {
		return (
			<li
				className={getSeparatorClassNames}
				key={`ac-breadcrumbs-separator-${n}`}
			>
				<AcIcon icon={ICONS.CHEVRON_RIGHT} />
			</li>
		);
	};

	const renderBreadcrumbs = useMemo(() => {
		if (!current_breadcrumbs) return null;

		const collection = current_breadcrumbs;
		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const item = collection[n];

			const { id, path, label, entity } = item;
			const key = item.id || AcUUID();

			const className =
				path === null
					? getEmptyLinkClassNames
					: n === len - 1
					? getActiveLinkClassNames
					: getLinkClassNames;
			const rest = n === len - 1 ? { 'aria-current': 'page' } : {};
			const Tag = path === null ? 'span' : Link;

			const object = (
				<li className={getItemClassNames} key={`ac-breadcrumbs-item-${key}`}>
					<Fade duration={100} appear>
						<Tag to={path} className={className} {...rest}>
							<span
								dangerouslySetInnerHTML={{
									__html: label,
								}}
							/>
						</Tag>
					</Fade>
				</li>
			);

			result.push(object);

			if (n < len - 1) {
				result.push(renderSeparator(n));
			}
		}
		return result;
	}, [current_breadcrumbs, renderSeparator]);

	return (
		<nav aria-label={'Breadcrumb'} className={getMainClassNames}>
			<ul className={getListClassNames}>{renderBreadcrumbs}</ul>
		</nav>
	);
};

export default withStore(observer(AcBreadcrumbs));
