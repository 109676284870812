import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  lazy,
  Suspense,
} from 'react';
import clsx from 'clsx';
const GoogleMapReact = lazy(() => import('google-map-react'));
import { MarkerClusterer } from '@googlemaps/markerclusterer';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
  ICONS,
  KEYS,
  MAP_CLUSTER_OPTIONS,
  MAP_OPTIONS,
  SIZES,
  THEMES,
  VISUALS,
} from '@constants';

// Imports => Utilties
import {
  AcIsSet,
  AcGetMapMarkerImage,
  AcGenerateMapMarkerElement,
} from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcDashboardMapWidgetItem from '@atoms/ac-dashboard-map-widget-item/ac-dashboard-map-widget-item.web';
import AcTag from '@atoms/ac-tag/ac-tag.web';
import AcSearchInput from '@atoms/ac-search-input/ac-search-input.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import {
  AcInfoWindow,
  itemRendererFactory,
} from '@atoms/ac-marker-info-window';

const _CLASSES = {
  MAIN: 'ac-dashboard-map-widget',
  LOADING: 'ac-dashboard-map-widget--loading',
  VIEW: {
    MAP: 'ac-dashboard-map-widget--map',
    LIST: 'ac-dashboard-map-widget--list',
  },
  FILTERS: 'ac-dashboard-map-widget-filters',
  AREA: 'ac-dashboard-map-widget-area',
  MAP: 'ac-dashboard-map-widget-map',
  LIST: {
    WRP: 'ac-dashboard-map-widget-list-wrp',
    MAIN: 'ac-dashboard-map-widget-list',
    ITEM: 'ac-dashboard-map-widget-list__item',
  },
  TOGGLE: {
    MAIN: 'ac-dashboard-map-widget-toggle',
    ITEM: 'ac-dashboard-map-widget-toggle__item',
    ACTIVE: 'ac-dashboard-map-widget-toggle__item--active',
  },
};

let _dashTimer = null;
let _dashMarkers = {
  list: [],
  object: {},
};
let _dashCluster = null;
let _dashDelay = null;

const AcDashboardMapWidget = ({
  collection = [],
  filters = [],
  entity = null,
  withSearch = false,
  searchOptions = {},
  loading = false,
}) => {
  const [ready, setReady] = useState(false);
  const [view, setView] = useState(KEYS.MAP);
  const [mapInstance, setMapInstance] = useState(null);

  const [activeMarker, setActiveMarker] = useState();
  const selectedMarker = useRef();

  const $container = useRef(null);
  const $listwrp = useRef(null);
  const $list = useRef(null);

  const entityItemRenderer = useMemo(() => {
    return itemRendererFactory(entity);
  }, [entity]);

  useEffect(() => {
    addEvents();
    calculateHeight();

    return () => removeEvents();
  }, []);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (loading && AcIsSet($listwrp) && AcIsSet($listwrp.current)) {
        $listwrp.current.scrollTop = 0;
      }
    });
  }, [loading]);

  useEffect(() => {
    if (_dashTimer) clearTimeout(_dashTimer);
    if (AcIsSet(mapInstance))
      _dashTimer = setTimeout(updateMarkerVisibility, 200);
  }, [mapInstance, collection]);

  const deselectMarker = (id) => {
    const collection = _dashMarkers.object;
    const marker = collection[id];
    if (AcIsSet(marker)) {
      const { maps } = mapInstance;
      window.requestAnimationFrame(() => {
        let iconElement = AcGenerateMapMarkerElement(
          marker.getAttribute('rel'),
          32,
          42,
          -16,
          -40.5
        );

        marker.content = iconElement;
        marker.zIndex = 10;
        marker.element.setAttribute(
          'aria-focus',
          'ac-dashboard-map-widget-marker--active'
        );
        handleAddEventsToMarker(
          marker,
          { over: true, leave: true, click: true },
          mapInstance
        );
      });
    }
  };

  useEffect(() => {
    const { map } = mapInstance || {};
    if (!map) {
      return;
    }
    if (activeMarker && activeMarker.marker) {
      const collection = _dashMarkers.object;
      selectedMarker.current = activeMarker.marker.id;
      handlePanTo(collection[selectedMarker.current], map);
    } else {
      deselectMarker(selectedMarker.current);
      selectedMarker.current = null;
    }
  }, [activeMarker, mapInstance]);

  const addEvents = () => {
    window.addEventListener('resize', calculateHeight, { passive: true });
  };

  const removeEvents = () => {
    window.removeEventListener('resize', calculateHeight, { passive: true });
  };

  const calculateHeight = () => {
    window.requestAnimationFrame(() => {
      let height = 550;

      if (AcIsSet($container) && AcIsSet($container.current)) {
        const rect = $container.current.getBoundingClientRect();
        const winrect = window.innerHeight;
        const offset = 25;

        if (winrect < rect.height + rect.top) {
          height = winrect - (rect.top + offset);
        } else if (winrect > rect.height + rect.top - offset) {
          height = winrect - (rect.top + offset);
        } else {
          height = rect.height;
        }

        height = height < 400 ? 400 : height;

        $container.current.style.height = `${height}px`;
      }
    });
  };

  const handleToggleViewType = (event, state) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.stopPropagation) event.stopPropagation();

    if (AcIsSet(state)) setView(state);
    else if (view === KEYS.MAP) setView(KEYS.LIST);
    else if (view === KEYS.LIST) setView(KEYS.MAP);
  };

  const handlePanTo = (marker, map) => {
    if (view === KEYS.LIST) return;
    if (!AcIsSet(map)) return;
    if (!AcIsSet(marker)) return;
    let position = marker.position;

    if (!AcIsSet(position)) return;

    map.panTo(position);
  };

  const handleMouseOver = (event, { id }, instance) => {
    if (!AcIsSet(id)) return;
    const evt = event.domEvent || event;

    if (_dashDelay) clearTimeout(_dashDelay);

    _dashDelay = setTimeout(() => {
      if ($list && $list.current) {
        $list.current.setAttribute('data-focus', true);

        const $item = document.getElementById(id);
        if ($item) {
          $item.setAttribute('data-focus', true);

          if (AcIsSet(instance)) {
            window.requestAnimationFrame(() => {
              const pos = $item.offsetTop;
              if (
                AcIsSet(pos) &&
                AcIsSet($listwrp) &&
                AcIsSet($listwrp.current)
              ) {
                $listwrp.current.scrollTop = pos - 20;
              }
            });
          }
        }
      }

      // Check if we actually have a valid map instance
      // or if we even have markers.
      // If not, skip the rest of the applicable logic
      if (!AcIsSet(mapInstance) && !AcIsSet(instance)) return;
      if (
        !AcIsSet(_dashMarkers) ||
        !AcIsSet(_dashMarkers.list) ||
        _dashMarkers.list.length === 0
      )
        return;
      if (
        !AcIsSet(_dashMarkers) ||
        !AcIsSet(_dashMarkers.object) ||
        !AcIsSet(_dashMarkers.object[id])
      )
        return;

      const { map, maps } = mapInstance || instance;
      const collection = _dashMarkers.object;

      const marker = collection[id];

      if (AcIsSet(marker)) {
        window.requestAnimationFrame(() => {
          if (evt.target.nodeName !== 'IMG') {
            handlePanTo(marker, map);
            if (marker.id !== selectedMarker.current) {
              setActiveMarker(null);
            }
          } else {
          }

          // Create a custom icon element (img or other HTML element)
          const iconElement = AcGenerateMapMarkerElement(
            marker.getAttribute('rel'),
            40,
            52,
            -20,
            -50
          );
          marker.content = iconElement;
          marker.zIndex = 20;
          marker.element.setAttribute(
            'aria-focus',
            'ac-dashboard-map-widget-marker--active'
          );
          handleAddEventsToMarker(
            marker,
            { over: false, leave: true, click: true },
            { map, maps }
          );
        });
      }
    }, 50);
  };

  const handleMouseLeave = (event, { id }, instance) => {
    if (_dashDelay) clearTimeout(_dashDelay);
    if (!AcIsSet(id)) return;

    const $item = document.getElementById(id);
    if ($item) $item.removeAttribute('data-focus');
    if ($list && $list.current) {
      $list.current.removeAttribute('data-focus');
      const focusedElements = $list.current?.querySelectorAll(
        '[data-focus="true"]'
      );
      if (focusedElements?.length) {
        focusedElements.forEach((el) => {
          if (el?.removeAttr) el.removeAttr('data-focus');
        });
      }
    }

    // Check if we actually have a valid map instance
    // or if we even have markers.
    // If not, skip the rest of the applicable logic
    if (!AcIsSet(mapInstance) && !AcIsSet(instance)) return;
    if (
      !AcIsSet(_dashMarkers) ||
      !AcIsSet(_dashMarkers.list) ||
      _dashMarkers.list.length === 0
    )
      return;
    if (
      !AcIsSet(_dashMarkers) ||
      !AcIsSet(_dashMarkers.object) ||
      !AcIsSet(_dashMarkers.object[id])
    )
      return;

    const { map, maps } = instance || mapInstance;
    const collection = _dashMarkers.object;

    const marker = collection[id];
    if (id === selectedMarker.current) {
      return false;
    }

    if (AcIsSet(marker)) {
      window.requestAnimationFrame(() => {
        // Create a custom icon element (img or another HTML element)
        const iconElement = AcGenerateMapMarkerElement(
          marker.getAttribute('rel'),
          32,
          42,
          -16,
          -40.5
        );

        // Set additional options
        marker.zIndex = 10;
        marker.element.setAttribute(
          'aria-focus',
          'ac-dashboard-map-widget-marker'
        );

        // If you need to update the marker's content dynamically, use setContent method
        marker.content = iconElement;
        handleAddEventsToMarker(
          marker,
          { over: true, leave: false, click: true },
          { map, maps }
        );
      });
    }
  };

  const getToggleItemClassNames = useCallback(
    (key) => {
      const active = key === view;
      return clsx(_CLASSES.TOGGLE.ITEM, active && _CLASSES.TOGGLE.ACTIVE);
    },
    [view]
  );

  const getToggleClassNames = useMemo(() => {
    return clsx(_CLASSES.TOGGLE.MAIN);
  }, []);

  const getListItemClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.ITEM);
  }, []);

  const getListClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.MAIN);
  }, []);

  const getListWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.LIST.WRP);
  }, []);

  const getMapClassNames = useMemo(() => {
    return clsx(_CLASSES.MAP);
  }, []);

  const getAreaClassNames = useMemo(() => {
    return clsx(_CLASSES.AREA);
  }, []);

  const getFiltersClassNames = useMemo(() => {
    return clsx(_CLASSES.FILTERS);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(
      _CLASSES.MAIN,
      view && _CLASSES.VIEW[view.toUpperCase()],
      loading && _CLASSES.LOADING
    );
  }, [view, loading]);

  const renderSearchInput = useMemo(() => {
    if (!AcIsSet(withSearch) || !withSearch) return null;

    const options = {
      placeholder: 'Find items',
      callback: () => {},
      ...searchOptions,
    };

    return <AcSearchInput {...options} />;
  }, [loading, withSearch, searchOptions]);

  const renderFilters = useMemo(() => {
    if (!AcIsSet(filters)) return null;

    const len = filters.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = filters[n];

      const { key, label, count } = item;

      const object = (
        <AcTag
          id={key}
          disabled={!count || count === 0}
          {...item}
          key={`ac-dashboard-map-widget-filter-${key}`}
        />
      );

      result.push(object);
    }

    return result;
  }, [filters]);

  const renderToggle = useMemo(() => {
    const options = [];

    return (
      <div className={getToggleClassNames}>
        <div
          className={getToggleItemClassNames(KEYS.LIST)}
          onClick={(event) => handleToggleViewType(event, KEYS.LIST)}
        >
          <AcIcon icon={ICONS.LIST_BULLETED} />
          <AcRipple theme={THEMES.WHITE} size={SIZES.SMALL} simple />
        </div>
        <div
          className={getToggleItemClassNames(KEYS.MAP)}
          onClick={(event) => handleToggleViewType(event, KEYS.MAP)}
        >
          <AcIcon icon={ICONS.MAP} />
          <AcRipple theme={THEMES.WHITE} size={SIZES.SMALL} simple />
        </div>
      </div>
    );
  }, [view, mapInstance]);

  const renderEmptyState = useMemo(() => {
    return (
      <AcContainer>
        <AcRow>
          <AcColumn>
            <AcHeading
              rank={3}
              className={'h-margin-top-25 h-margin-bottom-15'}
            >
              MIQIP Portal
            </AcHeading>
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcRichContent
              content={
                '<p>Welcome to the environment, where you can setup, manage and report on your projects with the IQIP equipment.</p>'
              }
              className={'h-margin-top-15'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcRichContent
              content={
                '<p>Once your contract has started, you will be able to manage your own project. In the meantime, you can watch our promotional video about the MIQIP Portal!</p>'
              }
              className={'h-margin-top-15'}
            />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <AcDivider className={'h-margin-y-25'} />
          </AcColumn>
        </AcRow>

        <AcRow>
          <AcColumn>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/H-kiLMKieik"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </AcColumn>
        </AcRow>
      </AcContainer>
    );
  }, []);

  const renderList = useMemo(() => {
    if (!AcIsSet(collection)) return renderEmptyState;
    if (collection.length === 0)
      return <AcEmptyBlock message={'No results found to display.'} />;

    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = Object.assign({}, collection[n]);

      let {
        id,
        name,
        object_no,
        equipment_type: type,
        company,
        stats,
        status,
        is_online,
        software,
      } = item;
      let progress = null;

      if (AcIsSet(name)) name = name;
      else if (AcIsSet(object_no)) name = object_no;

      if (entity === KEYS.PROJECTS && AcIsSet(stats)) {
        progress = {
          value: stats.piles_driven,
          total: stats.pile_count,
        };
      }

      let group =
        (AcIsSet(type) && AcIsSet(type.group) && type.group) || KEYS.PROJECTS;
      type = !AcIsSet(type) ? KEYS.PROJECTS : type;

      let software_version = null;

      if (software?.c36_operation_unit) {
        software_version = software?.c36_operation_unit;
      } else if (software?.CB) {
        software_version = item?.software?.CB;
      }

      const data = {
        id,
        name,
        company,
        type,
        group,
        progress,
        status,
        is_online,
        software: software_version,
      };

      const object = (
        <AcDashboardMapWidgetItem
          key={`ac-dashboard-map-widget-item-${id}`}
          {...data}
          group={group}
          ref={(node) => (item.ref = node)}
          mouseover={handleMouseOver}
          mouseleave={handleMouseLeave}
        />
      );

      result.push(object);
    }

    return (
      <ul className={getListClassNames} ref={$list}>
        {result}
      </ul>
    );
  }, [collection, mapInstance]);

  const renderMarker = (
    { position, type, id, name = '', object_no = '', company = null },
    map,
    maps
  ) => {
    const url = AcGetMapMarkerImage(type);
    // Marker image
    // original size: 268 x  352

    const iconElement = AcGenerateMapMarkerElement(url, 32, 43, -16, -40.5);

    let title = AcIsSet(name) ? name : AcIsSet(object_no) ? object_no : '';
    if (AcIsSet(company) && AcIsSet(company.name)) {
      title = `${title} | ${company.name}`;
    }

    const newMarker = new maps.marker.AdvancedMarkerElement({
      position,
      content: iconElement,
      map,
      id,
      title,
      zIndex: 10,
    });

    newMarker.setAttribute('id', id);
    newMarker.setAttribute('rel', url);

    handleAddEventsToMarker(
      newMarker,
      { over: true, leave: true, click: true },
      { map, maps }
    );

    return newMarker;
  };

  const updateMarkerVisibility = () => {
    if (
      !AcIsSet(_dashMarkers) ||
      !AcIsSet(_dashMarkers.list) ||
      _dashMarkers.list.length === 0
    )
      return;
    if (!AcIsSet(mapInstance)) return;

    const { map, maps } = mapInstance;

    const list = _dashMarkers.list;
    const len = list.length;
    let n = 0;

    const items = collection;
    const ilen = items.length;

    const invisible = [];
    const visible = [];

    let bounds = new maps.LatLngBounds();

    for (n; n < len; n++) {
      const marker = list[n];

      let present = null;
      let b = 0;

      // Let's see if we actually have a corresponding marker on the map
      for (b; b < ilen; b++) {
        const it = items[b];

        if (it.id === marker.id) {
          // Found  one!
          present = it;
          break;
        }
      }

      const is_visible = marker.visible;

      // Check if we found a corresponding marker
      if (AcIsSet(present)) {
        // We did! Now make it visible (if it wasn't visible already)
        const position = marker.position;
        bounds.extend(position);
        if (!is_visible) marker.map = map;
        visible.push(marker);
      } else {
        // We did not! Now make it invisible (if it was visible already)
        if (is_visible) marker.map = null;
        invisible.push(marker);
      }
    }

    // If we have initiated cluster,
    // update that instance with new visible markers
    if (AcIsSet(_dashCluster)) {
      _dashCluster.clearMarkers();
      _dashCluster.addMarkers(visible);
    }

    // If we have visible markers, fit the bounds
    if (AcIsSet(maps) && AcIsSet(map) && visible.length > 0) {
      map.fitBounds(bounds);
    }
  };

  const handleAddEventsToMarker = (
    marker,
    options = { over: true, leave: true, click: true },
    instance
  ) => {
    if (marker?.content) {
      if (options.over) {
        marker.content.addEventListener(
          'mouseover',
          (event) => {
            handleMouseOver(event, marker, instance);
          },
          { once: true }
        );
      }
      if (options.leave) {
        marker.content.addEventListener(
          'mouseout',
          (event) => {
            handleMouseLeave(event, marker, instance);
          },
          { once: true }
        );
      }
      if (options.click) {
        marker.content.addEventListener(
          'click',
          () => {
            setActiveMarker({ type: 'marker', marker });
          },
          { once: true }
        );
      }
    }
  };

  const init = async ({ map, maps }) => {
    if (!AcIsSet(collection)) return null;
    if (!AcIsSet(map)) return null;

    // First check if markers are already on the map,
    // and hide/remove them
    if (
      AcIsSet(_dashMarkers) &&
      AcIsSet(_dashMarkers.list) &&
      _dashMarkers.list.length > 0
    ) {
      const mlen = _dashMarkers.list.length;
      let b = 0;

      for (b; b < mlen; b++) {
        const mark = _dashMarkers.list[b];
        mark.setMap(null);
      }
    }

    // Loop through the known items
    // if they have a known location, create a mapmarker
    const len = collection.length;
    let n = 0;

    let markers = {
      list: [],
      object: {},
    };
    let bounds = new maps.LatLngBounds();
    let group = entity;

    for (n; n < len; n++) {
      const item = collection[n];

      let { location, id, equipment_type } = item;

      if (!AcIsSet(location)) continue;

      const position = new maps.LatLng(location.lat, location.lng);

      bounds.extend(position);

      // Check if we are dealing with equipment
      if (AcIsSet(equipment_type) && AcIsSet(equipment_type.group))
        group = equipment_type.group;

      // Determnine the 'type' of marker to render
      // If we don't have a group, use the default marker (which is also used as a 'Project' marker)
      const type = AcIsSet(group) ? group : KEYS.PROJECT;
      const marker = await renderMarker(
        { ...item, position, type, id },
        map,
        maps
      );

      // If we succesfully created a marker
      // add the events
      if (marker?.content) {
        handleAddEventsToMarker(
          marker,
          { over: true, leave: true, click: true },
          { map, maps }
        );

        markers.list.push(marker);
        markers.object[id] = marker;
      }
    }

    // Check if we actually have markers,
    // and if we do, initiate the clusters
    _dashMarkers = markers;

    if (AcIsSet(markers))
      _dashCluster = new MarkerClusterer(
        map,
        markers.list,
        MAP_CLUSTER_OPTIONS
      );

    maps.event.addListener(_dashCluster, 'click', (cluster) => {
      const numMarkers = cluster.getMarkers().length;
      const mz = cluster.getMarkerClusterer().getMaxZoom();
      if (mz !== null && map.getZoom() >= mz && numMarkers > 1) {
        setActiveMarker({ type: 'cluster', marker: cluster });
      }
    });

    maps.event.addListenerOnce(map, 'tilesloaded', () => {
      if (markers.list.length > 0) {
        // Check if we actually have markers,
        // and if we do, pan the map to fit the bounds
        map.fitBounds(bounds);

        maps.event.addListenerOnce(map, 'idle', () => {
          const zoomLevel = map.getZoom();
          if (zoomLevel < 3) {
            map.setZoom(3);
          }
        });
      }
    });

    const styledMapType = new maps.StyledMapType(MAP_OPTIONS.styles);
    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');

    setMapInstance({ maps, map });
  };

  const renderInfoWindow = useMemo(() => {
    const hideInfoWindow = () => setActiveMarker(null);
    const getItem = (item) =>
      collection.find((i) => {
        return `${i.id}` === `${item.id}`;
      });
    return (
      <AcInfoWindow
        getItem={getItem}
        onHideInfoWindow={hideInfoWindow}
        activeMarker={activeMarker}
        itemRenderer={entityItemRenderer}
      />
    );
  }, [activeMarker, collection, entityItemRenderer]);

  const renderMap = useMemo(() => {
    const { maps_key: key } = config;

    return (
      <Suspense fallback={<div />}>
        <GoogleMapReact
          {...MAP_OPTIONS}
          bootstrapURLKeys={{
            key,
            libraries: ['places', 'markers', 'marker'],
            language: 'en',
            region: 'en',
          }}
          onGoogleApiLoaded={init}
        />
      </Suspense>
    );
  }, [init]);

  return (
    <div className={getMainClassNames} ref={$container}>
      <div className={getFiltersClassNames}>
        {renderSearchInput}
        {renderFilters}
        {renderToggle}
      </div>

      <div className={getAreaClassNames}>
        <div className={getMapClassNames}>
          {renderMap}
          {renderInfoWindow}
        </div>
        <div className={getListWrpClassNames} ref={$listwrp}>
          <AcLoader loading={loading} cover pitch />
          {renderList}
        </div>
      </div>
    </div>
  );
};

export default memo(AcDashboardMapWidget);
