import React, { useEffect, useCallback, useMemo, memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import { register, unregister } from '@src/registerServiceWorker';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, TYPES } from '@constants';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-general-notice',
};

const AcGeneralNotice = ({ store: { freshContentIsAvailable } }) => {
	const navigate = useNavigate();

	const handleForceRefresh = useCallback(async () => {
		window.location.reload(true);
	});

	const getReloadButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			onClick: handleForceRefresh,
		};
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const isReady = useMemo(() => {
		console.log('isReady => freshContentIsAvailable', freshContentIsAvailable);
		return freshContentIsAvailable;
	}, [freshContentIsAvailable]);

	return (
		<Fade direction={'top'} distance={'50px'} duration={250} reverse={!isReady}>
			<header className={getMainClassNames} id={'ac-general-notice'}>
				<AcIcon icon={ICONS.LIGHTNING_BOLT} />
				<u>
					A fresh version of the <mark>MIQIP Portal</mark> is available!
				</u>
				<button {...getReloadButtonOptions}>Click here to reload</button>
			</header>
		</Fade>
	);
};

export default withStore(observer(AcGeneralNotice));
