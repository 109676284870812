import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
	useMemo,
	memo,
} from 'react';
import { Link } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, THEMES, SIZES, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-contextual-menu',
	OPEN: 'ac-contextual-menu--open',
	TOGGLE: {
		MAIN: 'ac-contextual-menu__toggle',
		ICON: 'ac-contextual-menu__toggle-icon',
	},
	DROPDOWN: {
		MAIN: 'ac-contextual-menu__dropdown',
		WRP: 'ac-contextual-menu__dropdown-wrp',
	},
	LIST: {
		MAIN: 'ac-contextual-menu__list',
		ITEM: 'ac-contextual-menu__item',
		LINK: 'ac-contextual-menu__link',
		DELETE: 'ac-contextual-menu__link--delete',
		LABEL: 'ac-contextual-menu__label',
		ICON: 'ac-contextual-menu__icon',
	},
};

const AcContextualMenu = ({ collection }) => {
	const [context, setContext] = useState(false);
	const $ref = useRef(null);

	useEffect(() => {
		addEvents();

		return () => removeEvents();
	}, []);

	const addEvents = () => {
		document.addEventListener('click', handleClick, false);
	};

	const removeEvents = () => {
		document.removeEventListener('click', handleClick, false);
	};

	const handleClose = () => {
		setContext(false);
	};

	const handleToggle = () => {
		if (context) setContext(false);
		else setContext(true);
	};

	const handleClick = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.target) {
			const $element = $ref.current;

			if ($element) {
				const inside = $element.contains(event.target);

				if (!inside) handleClose();
			}
		}
	};

	const handleCallback = (event, callback) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (callback) callback();
	};

	const getIconClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.ICON);
	});

	const getLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.LABEL);
	});

	const getLinkClassNames = useCallback((type) => {
		return clsx(
			_CLASSES.LIST.LINK,
			type && type === TYPES.DELETE && _CLASSES.LIST.DELETE
		);
	});

	const getListItemClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.ITEM);
	});

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.MAIN);
	});

	const getDropdownClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.MAIN);
	});

	const getDropdownWrpClassNames = useMemo(() => {
		return clsx(_CLASSES.DROPDOWN.WRP);
	});

	const getToggleIconClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.ICON);
	});

	const getToggleClassNames = useMemo(() => {
		return clsx(_CLASSES.TOGGLE.MAIN);
	});

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, context && _CLASSES.OPEN);
	}, [context]);

	const renderActions = useMemo(() => {
		if (!collection || collection.length === 0) return null;

		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const group = collection[n];

			if (!AcIsSet(group)) continue;

			const grouplen = group.length;
			let b = 0;
			let groupresult = [];

			for (b; b < grouplen; b++) {
				const item = group[b];
				const { icon, label, callback, type, to, disabled } = item;

				const Tag = type === TYPES.LINK ? Link : 'div';

				const props = {
					className: getLinkClassNames(type),
				};

				if (AcIsSet(callback))
					props.onClick = (event) => handleCallback(event, callback);
				if (AcIsSet(to)) props.to = to;
				if (!AcIsSet(callback) && !AcIsSet(to)) props.disabled = true;
				else if (disabled) props.disabled = disabled;

				const object = (
					<li
						key={`ac-contextual-menu-item-${n}-${b}-${AcUUID()}`}
						className={getListItemClassNames}
					>
						<Tag {...props}>
							<AcRipple
								theme={type === TYPES.DELETE ? THEMES.ALPHA : THEMES.PITCH}
								size={SIZES.SMALL}
								simple
							/>
							{icon && <AcIcon icon={icon} className={getIconClassNames} />}
							<span
								dangerouslySetInnerHTML={{
									__html: label,
								}}
								className={getLabelClassNames}
							/>
						</Tag>
					</li>
				);

				groupresult.push(object);
			}

			result.push(
				<ul
					key={`contextual-menu-list-${n}-${AcUUID()}`}
					className={getListClassNames}
				>
					{groupresult}
				</ul>
			);
		}

		return result && result.length > 0 ? result : null;
	}, [collection]);

	return (
		<div className={getMainClassNames} ref={$ref}>
			<div className={getToggleClassNames} onClick={handleToggle}>
				<AcIcon
					icon={ICONS.DOTS_VERTICAL}
					className={getToggleIconClassNames}
				/>
				<AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
			</div>

			<div className={getDropdownWrpClassNames}>
				<div className={getDropdownClassNames}>{renderActions}</div>
			</div>
		</div>
	);
};

export default withStore(observer(AcContextualMenu));
