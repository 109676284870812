import React, { useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { DATETIME_FORMATS, KEYS, THEMES, TYPES, VARIANTS } from '@constants';
import { AcFormatDate } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcDatepickerInput from '@atoms/ac-datepicker-input/ac-datepicker-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-add-contract-modal',
  CONTENT: 'ac-add-contract-modal__content',
};

const ExpDateModal = ({
  store: { contracts, ui },
  expires,
  contractId,
  equipmentNo,
  submit,
  afterSubmit,
}) => {
  const [expDate, setExpDate] = useState(expires);
  const { is_busy } = contracts;

  const getExpDateInputOptions = useMemo(() => {
    return {
      type: TYPES.DATE,
      label: 'Expiration date',
      placeholder: 'dd-mm-yyyy',
      range: 'expiration',
      value: expDate,
      callback: (...values) => {
        setExpDate(values[2]);
      },
      required: false,
    };
  }, [setExpDate, expDate]);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: async (event) => {
        if (event && event.preventDefault) {
          event.preventDefault();
        }
        await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
    };
  }, []);

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      title: 'Confirm',
      callback: async (event) => {
        if (event && event.preventDefault) {
          event.preventDefault();
        }
        const expiresDate = AcFormatDate(
          expDate,
          DATETIME_FORMATS.DATE,
          DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR
        );
        submit(contractId, {
          expires_at: expiresDate,
          equipment_id: equipmentNo,
        }).then(async () => {
          await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
          if (afterSubmit) afterSubmit(equipmentNo);
        });
      },
    };
  }, [expDate, submit, afterSubmit]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <AcContainer fluid>
          <AcRow className={'h-margin-top-0 h-margin-bottom-15'}>
            <AcColumn xs={12}>
              Set the date to revoke access of the control unit.
            </AcColumn>
          </AcRow>
          <AcRow className={'h-margin-top-0'}>
            <AcColumn sm={6}>
              <AcDatepickerInput {...getExpDateInputOptions} />
            </AcColumn>
          </AcRow>

          <AcRow className={'h-margin-top-20'}>
            <AcColumn className={'h-text--align-right'}>
              <span className={'h-margin-right-15'}>
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </span>
              <AcButton {...getSubmitButtonOptions}>
                <span>Confirm</span>
              </AcButton>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>
      {is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export const ConnectedExpDateModal = withStore(observer(ExpDateModal));
