import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';

// Imports => Data
import { COUNTRIES_LIST } from '@data/countries.data';

// Imports => Constants
import { TYPES, ICONS, SIZES, THEMES } from '@constants';

// Imports => Hooks
import { useClickOutside, useEscapeKey, useDebounceInput } from '@hooks';

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcDropDown from '@atoms/ac-dropdown/ac-dropdown';
import AcInputAdornment from '@atoms/ac-input-adornment/ac-input-adornment';
import AcTextInput from '@src/atoms/ac-text-input/ac-text-input.web';

/**
 * Search for companies.
 * @param {Object} props - Properties of AcCompanySearch
 * @param {string|null} props.value - Initial value.
 * @param {function} props.onSelectItem - Callback for selecting an item in the dropdown.
 * @param {array} props.results - List of items to render.
 * @param {string} props.filter - Function that needs to be called when the search changes.
 */

export const AcCompanySearch = ({
  value = null,
  onSelectItem,
  results,
  filter,
  loading,
}) => {
  const [
    onInputChange,
    setInputValue,
    inputValue,
    debouncedValue,
  ] = useDebounceInput(filter);
  const { setRef, visible, setVisible } = useClickOutside(false);
  const escapePressed = useEscapeKey(false);
  const textFieldRef = useRef();
  const [selectedItem, setSelectedItem] = useState(value);

  useEffect(() => {
    textFieldRef.current?.focus?.();
  }, []);

  useEffect(() => {
    setVisible(results && results.length);
  }, [results]);

  const handleSelectItem = useCallback(
    (item) => {
      if (item) {
        onSelectItem(item);
        setVisible(false);
        setSelectedItem(item.name);
        textFieldRef.current.value = item.name;
        textFieldRef.current.focus();
      }
    },
    [onSelectItem, setVisible, setSelectedItem, inputValue, debouncedValue]
  );
  const handleClear = useCallback(() => {
    setSelectedItem(null);
    onSelectItem(null);
    setInputValue('');
    textFieldRef.current.value = '';
    textFieldRef.current.focus();
  }, [onSelectItem, setInputValue, setSelectedItem]);


  const getInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      placeholder: 'Search for company',
      name: 'company',
      callback: onInputChange
    };
  }, [onInputChange]);

  useEffect(() => {
    if (selectedItem) textFieldRef.current.value = selectedItem;
  }, [selectedItem]);

  return (
    <div style={{ position: 'relative' }} ref={setRef}>
      <AcTextInput
        {...getInputOptions}
        ref={textFieldRef}
        loading={loading}
        startIcon={ICONS.MAGNIFY}
        endAdornment={
          <AcInputAdornment
            hideWhen={!inputValue && !selectedItem}
            style={{ color: "#0085d2", cursor: "pointer" }}
          >
            <div onClick={handleClear}>
              <AcIcon icon={ICONS.CLOSE} />
              <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />
            </div>
          </AcInputAdornment>
        }
      />
      {!!visible && !escapePressed && (
        <AcDropDown
          results={results}
          onClick={handleSelectItem}
          render={(item) => (
            <>
              <span>{item.name}</span>
              <span>{COUNTRIES_LIST[item.country_code]}</span>
            </>
          )}
        />
      )}
    </div>
  );
};
