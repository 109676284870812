// Imports => React
import React, { forwardRef, useImperativeHandle, useEffect, memo } from 'react';
import config from '@config';
import Favicon from '@assets/images/favicon.ico';
import { withStore } from '@src/stores';
import { observer } from 'mobx-react-lite';

let acm_timer = null;
let acm_timestamp = new Date().getTime();

const AcActivityMonitor = forwardRef(({ callback, authorized, store: { auth } }, ref) => {
	useEffect(() => {
		console.group('[component] AcActivityMonitor => Constructor');
		console.log('Autologout: ', config.autologout);
		console.groupEnd();

		window.FaviconNotification.init({
			url: Favicon,
			color: '#d50000',
			lineColor: '#fff',
		});

		if (config.autologout && config.autologout.active) {
			addEvents();
			checkActivity();
		}

		return () => {
			removeEvents();
		};
	}, []);

	const getDifference = (dt2, dt1) => {
		var diff = (dt2 - dt1) / 1000;
		diff /= 60;
		return Math.abs(Math.round(diff));
	};

	const isActive = () => {
		if (!authorized) return false;
		const now = Date.now();
		const end = auth.current_last_activity;

		const difference = getDifference(now, end);
		return difference < (config.autologout.time || 20)
	}

	useImperativeHandle(ref, () => ({
		restart: () => {
			if (!authorized) return;
			auth.setLastActivity(Date.now())
			checkActivity();
		},
		stop: () => {
			if (acm_timer) clearTimeout(acm_timer);
		},
		check: () => isActive()
	}));

	const checkActivity = () => {
		if (acm_timer) clearTimeout(acm_timer);

		acm_timer = setTimeout(() => {
			if (auth.current_last_activity < acm_timestamp) {
				auth.setLastActivity(acm_timestamp)
			}
			if (isActive()) {
				checkActivity();
			} else {
				if (acm_timer) clearTimeout(acm_timer);
				if (callback) callback();
			}
		}, 5 * 1000);
	};

	const updateActivity = () => {
		acm_timestamp = new Date().getTime();
	};

	const addEvents = () => {
		window.addEventListener('mousemove', updateActivity, { passive: true });
		window.addEventListener('keydown', updateActivity, { passive: true });
		window.addEventListener('keyup', updateActivity, { passive: true });
		window.addEventListener('unAuthenticate', stop);
	};

	const removeEvents = () => {
		window.removeEventListener('mousemove', updateActivity, { passive: true });
		window.removeEventListener('keydown', updateActivity, { passive: true });
		window.removeEventListener('keyup', updateActivity, { passive: true });
	};

	return null;
});

export default withStore(observer(AcActivityMonitor));
