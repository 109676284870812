import React, { useCallback, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsArray } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcCard from '@atoms/ac-card/ac-card.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import { action } from 'mobx';

const _CLASSES = {
  MAIN: 'ac-details-card',
  CONTENT: 'ac-details-card__content',
  ROW: 'ac-details-card__row',
  TITLE: 'ac-details-card__title',
  LABEL: 'ac-details-card__label',
  VALUE: 'ac-details-card__value',
  EDIT: 'ac-details-card__edit',
};

const AcDetailsCard = ({
  title,
  items,
  edit = null,
  context = null,
  actions = null,
}) => {
  const getEditIconClassNames = useMemo(() => {
    return clsx(_CLASSES.EDIT);
  }, []);

  const getValueClassNames = useMemo(() => {
    return clsx(_CLASSES.VALUE);
  }, []);

  const getLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.LABEL);
  }, []);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.TITLE);
  }, [title]);

  const getRowClassNames = useMemo(() => {
    return clsx(_CLASSES.ROW);
  }, []);

  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT);
  });

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const renderItems = useCallback(
    (collection) => {
      const len = collection.length;
      let n = 0;
      let result = [];

      for (n; n < len; n++) {
        const item = collection[n];
        const { label, value, type, to } = item;

        let Tag = 'div';
        const props = {
          className: getValueClassNames,
        };

        if (type === TYPES.LINK && AcIsSet(to)) {
          Tag = Link;
          props.to = to;
        } else if (type === TYPES.LINK && !AcIsSet(to)) props.disabled = true;

        const object = (
          <div
            key={`ac-details-card-item-${AcUUID()}`}
            className={getRowClassNames}
          >
            {label && (
              <span
                className={getLabelClassNames}
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
              />
            )}
            {value && (
              <Tag
                {...props}
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              />
            )}
          </div>
        );

        result.push(object);
      }

      return result;
    },
    [items]
  );

  const renderDetails = useMemo(() => {
    const collection = items;
    const len = collection.length;
    let n = 0;
    let result = [];

    if (AcIsSet(collection[0]) && AcIsArray(collection[0])) {
      for (n; n < len; n++) {
        const group = collection[n];

        const rendered = renderItems(group);
        const object = (
          <AcColumn xs={12} sm={6} key={`ac-details-card-group-${n}`}>
            {rendered}
          </AcColumn>
        );

        result.push(object);
      }

      return <AcRow>{result}</AcRow>;
    }

    result = renderItems(collection);

    return result;
  }, [items]);

  const renderEdit = useMemo(() => {
    if (!AcIsSet(edit)) return null;

    return (
      <AcIcon
        icon={ICONS.EDIT_OUTLINE}
        callback={edit}
        className={getEditIconClassNames}
        title={'Edit'}
      />
    );
  }, [edit]);

  const renderContextMenu = useMemo(() => {
    if (!AcIsSet(context)) return null;

    return context;
  }, [context]);

  return (
    <AcCard className={getMainClassNames}>
      <div className={getContentClassNames}>{renderDetails}</div>
      {edit && renderEdit}
      {context && renderContextMenu}

      {actions && (
        <AcRow>
          <AcColumn xs={12} className={'h-text--align-right'}>
            {actions}
          </AcColumn>
        </AcRow>
      )}
    </AcCard>
  );
};

export default memo(AcDetailsCard);
