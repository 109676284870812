// Imports => React
import React, { useMemo } from 'react';
import clsx from 'clsx';

// Imports => Constants;
import { TYPES } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Atoms
import AcImage from '@atoms/ac-image/ac-image.web';

const _CLASSES = {
  MAIN: 'ac-hero-visual',
  IMAGE: {
    MAIN: 'ac-hero-visual__image',
    WRP: 'ac-hero-visual__image-wrp',
  },
};

const AcHeroVisual = ({ image }) => {
  const getImageClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.MAIN);
  }, []);

  const getImageWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.WRP);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const renderImage = useMemo(() => {
    if (!AcIsSet(image)) return null;

    return (
      <AcImage
        source={image}
        type={TYPES.BACKGROUND}
        wrpClassName={getImageWrpClassNames}
        className={getImageClassNames}
      />
    );
  }, [image]);

  return <div className={getMainClassNames}>{renderImage}</div>;
};

export default AcHeroVisual;
