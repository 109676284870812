import React, { useCallback, useMemo, useState } from 'react';
import loadable from '@loadable/component';
const AcTable = loadable(() => import('@components/ac-table/ac-table.web'));
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  DATETIME_FORMATS,
  TYPES,
  TITLES,
} from '@constants';
// Imports => Hooks
import { usePermissions } from '@hooks';
import { useStore } from '@stores';
// Imports => Utilities
import { AcHasErrors } from '@utils';

export const AcContractTable = ({
  contracts,
  deleteContract,
  actions: addActions = [],
}) => {
  const { can } = usePermissions();
  const { ui } = useStore();

  const actions = can(PERMISSIONS.CONTRACT.DELETE) && [
    [
      {
        label: 'Delete',
        field: KEYS.OBJECT,
        icon: ICONS.TRASH_CAN_OUTLINE,
        type: TYPES.DELETE,
        callback: deleteContract,
      },
    ],
    ...addActions,
  ];

  const items = contracts.map((c) => [
    {
      key: 'id',
      label: 'id',
      link: c.id,
      value: c.id,
    },
    {
      key: 'name',
      label: 'View contract',
      link: { id: c.id, entity: 'contracts', active: true },
      value: c.name,
    },
    {
      key: 'company_name',
      label: 'View contract',
      link: { id: c.company.id, entity: 'companies', active: true },
      value: c.company.name,
    },
    {
      key: 'date_start',
      label: 'First day',
      value: c.date_start,
    },
    {
      key: 'date_end',
      label: 'Last day',
      value: c.date_end,
    },
    {
      ...(addActions.length && {
        key: 'expires_at',
        label: 'Expires at',
        value: c.expires_at || '',
      }),
    },
  ]);

  const options = {
    columns: [
      {
        key: 'id',
        label: 'Id',
        sortable: false,
        visible: false,
      },
      {
        key: 'name',
        label: 'Contract name',
        sortable: false,
        visible: true,
      },
      {
        key: 'company_name',
        label: 'Company',
        sortable: false,
        visible: true,
      },
      {
        key: 'date_start',
        label: 'First day',
        sortable: false,
        visible: true,
      },
      {
        key: 'date_end',
        label: 'Last day',
        sortable: false,
        visible: true,
      },
      {
        ...(addActions.length && {
          key: 'expires_at',
          label: 'Expires at',
          sortable: false,
          visible: true,
        }),
      },
    ],
    rows: items,
    pagination: null,
    actions,
  };
  return <AcTable {...options} />;
};

export const useRenderContracts = (contractList, actions) => {
  const { contracts, ui } = useStore();

  const handleDelete = useCallback((object) => {
    const { name, id } = object;
    ui.confirm({
      instance: contracts,
      title: TITLES.DELETE_CONTRACT,
      content: `<p>You are about to permanently delete contract <strong>${name}</strong>.</p><p class="h-margin-top-15"><strong>Are you sure you want to proceed?</strong></p>`,
      confirm: {
        label: 'Yes, delete',
        callback: () => {
          return new Promise((resolve, reject) => {
            contracts
              .delete({ id, name })
              .then(() => {
                // setCurrentContracts((contracts) =>
                //   contracts.filter((c) => c.id !== id)
                // );
                resolve();
              })
              .catch((error) => {
                const errors = AcHasErrors(error);
                if (!AcHasErrors(error)) return;

                ui.display_linked_to({
                  errors,
                  title: TITLES.FAILED_TO_DELETE_CONTRACT,
                });

                reject(error);
              });
          });
        },
      },
    });
  });

  return useMemo(() => {
    if (!contractList) return null;

    return (
      <AcContractTable
        {...(actions && {
          actions,
        })}
        deleteContract={(obj) => handleDelete(obj)}
        contracts={contractList}
      />
    );
  }, [contractList, actions]);
};
